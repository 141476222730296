import axios from "axios";

// export const base_url = "http://13.233.101.193:8000";


// export const base_url = "http://prod-v4-api.eduaratech.com/";
// export const base_url = "http://school.ed5-ai.online/";
// export const base_url = "https://gurukrupaa-app.ed5-ai.online/";
// export const base_url = "http://dev-api.eduaratech.com/";
// export const base_url = "https://rmg-app.ed5-ai.online/";
export const base_url = "https://demoadmin.ed5-ai.online/";

//Stag Url


//http://stag-api.eduaratech.com/

// const getToken = () => {
//     var token = ``;
//     if (localStorage.getItem("ed5_user_cred_context")) {
//         let valueVar = localStorage.getItem("ed5_user_cred_context");
//         let parseValue = JSON.parse(valueVar);
//         token = parseValue.token.access;
//     }
//     return token
// }


export const _access_token = localStorage.getItem("ed5_token");
var _headers =
  _access_token === null || _access_token === undefined
    ? {
        "Content-Type": "application/json",
      }
    : {
        "Content-Type": "application/json",
        Authorization: `Bearer ${_access_token}`,
      };

export var api_open = axios.create({
  baseURL: base_url,
  timeout: 300000000,
  headers: {
    "Content-Type": "application/json",
  },
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export var api_token = axios.create({
  baseURL: base_url,
  timeout: 300000000,
  headers: _headers,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export function setToken(_token) {
  return new Promise((resolve, reject) => {
    try {
      localStorage.setItem("ed5_token", _token);
      api_token = axios.create({
        baseURL: base_url,
        timeout: 300000000,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${_token}`,
          accept:
            "application/json;version=2, text/plain, */*, application/json, text/plain, */*",
        },
        transformRequest: [
          function (data, headers) {
            return JSON.stringify(data);
          },
        ],
      });

      resolve("Successfully set ed5 token.");
    } catch {
      reject("Error to ser ed5 token");
    }
  });
}

export async function formDataApi(
  apiName = "",
  formData,
  patch = false,
  apiUrl = "external/v1/"
) {
  let data = {};
  const token = JSON.parse(localStorage.getItem("ed5_token"));
  if (patch)
    await axios
      .patch(base_url + apiUrl + apiName, formData, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "content-type": "multipart/form-data",
        },
        validateStatus: (status) => status < 500,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201)
          return (data = response.data.data);
        return (data = response.data);
      })
      .catch((error) => console.log(error));
  else
    await axios
      .post(base_url + apiUrl + apiName, formData, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "content-type": "multipart/form-data",
        },
        validateStatus: (status) => status < 500,
      })
      .then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.data.data
        )
          return (data = response.data.data);
        return (data = response.data);
      })
      .catch((error) => console.log(error));
  return data;
}
